<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Salary</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Month Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Salary Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select class="mr-2 mg-t-5" v-model="params.session_year">
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>
          <select
            v-model="params.branch"
            class="filter-input mr-2 mg-t-5"
            @change="getData"
          >
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in salaryDataAtrributes.branchs"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/salary-report">
                Employee Wise
              </option>
              <option value="/general-report/salary-report/payroll-wise">
                Payroll Wise
              </option>
              <option value="/general-report/salary-report/transaction-wise">
                Transaction Wise
              </option>
              <option value="/general-report/salary-report/month-wise">
                Month Wise
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-25p">Month</th>
              <th class="wd-10p">Gross Salary</th>
              <th class="wd-10p">Tax</th>
              <th class="wd-10p">Net Salary</th>
              <th class="wd-10p">Total Advance</th>
              <th class="wd-10p">Advance Due</th>
            </tr>
          </thead>
          <tbody v-if="!loading && !error">
            <tr v-for="(salary, index) in monthWiseDatas" :key="index">
              <th scope="row" class="table-start-item">{{ ++index }}</th>
              <td>{{ salary.month_name }} - {{ salary.year }}</td>
              <td>{{ parseDigitForSlip(salary.gross_total) }}</td>
              <td>
                <div v-for="tax in salary.taxes" :key="tax.slug">
                  <span>{{ tax.title }}:</span
                  ><span>{{ parseDigitForSlip(tax.amount) }}</span>
                  <br />
                </div>
              </td>
              <td>{{ parseDigitForSlip(salary.net_total) }}</td>
              <td>{{ parseDigitForSlip(salary.advance) }}</td>
              <td>{{ parseDigitForSlip(salary.payable) }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" class="text-center">Loading...</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" class="text-center">{{ error_message }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Services from "./Services/Services";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sessionYears: [ "2021"],
      route: this.$route.path,
      params: {
        branch: "",
        session_year: 2021,
      },
      loading: false,
      error: false,
      error_message: "",
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists4",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("salaryReports", ["monthWiseDatas", "salaryDataAtrributes"]),
  },
  methods: {
    filterReportType() {
      this.$router.push(this.route);
    },
    print() {
      window.print();
    },
    getData() {
      this.loading = true;
      Services.getMonthWiseReport(this.params)
        .then((res) => {
          this.$store.commit(
            "salaryReports/setMonthWiseDatas",
            res.data.data.data
          );
          this.$store.commit(
            "salaryReports/setSalaryDataAttributes",
            res.data.data.attributes
          );
        })
        .catch((err) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
          this.$store.commit("salaryReports/setTransactionWiseDatas", []);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download() {
      Services.getMonthWiseExcel(this.params)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const elink = document.createElement("a");
          elink.download = "salary-month-wise-report.xlsx";
          elink.style.display = "none";
          elink.style.font = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>