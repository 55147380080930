import axios from 'axios';
import { hostUrl } from "../config/UrlConfig";

class Api {
    get(url, headers = {}, params = {}) {
        url = this.prepareUrl(url);
        const options = {
            headers: headers,
            params: params
        };
        return axios.get(url, options);
    }

    post(url, data, headers = {}) {
        url = this.prepareUrl(url);
        const options = {
            headers: headers
        };
        return axios.post(url, data, options);
    }
    patch(url, data, headers = {}) {
        url = this.prepareUrl(url);
        const options = {
            headers: headers
        };
        return axios.patch(url, data, options);
    }
    delete(url, headers = {}) {
        url = this.prepareUrl(url);
        const options = {
            headers: headers
        };
        return axios.delete(url, options);
    }
    getFile(url, headers = {}, params = {}) {
        url = this.prepareUrl(url);
        const options = {
            headers: headers,
            params: params,
            responseType:'blob'
        };
        return axios.get(url, options);
    }
    prepareUrl(endpoint) {
        return hostUrl + endpoint;
    }
}

export default new Api();