import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class SalaryReportService{
    getEmployeeWiseReport(param){
        const url = `api/salary-report/employee-wise/filter`
        const params ={
                'page-index':param.pageIndex,
                'branch':param.branch,
                'session_year':param.session_years, 
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getEmployeeWiseExcel(param){
        const url = `api/salary-report/employee-wise/excel`
        const params ={
                'page-index':param.pageIndex,
                'branch':param.branch,
                'session_year':param.session_years, 
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    }
    getPayrollExcel(param){
        const url = `api/salary-report/payroll-wise/excel`
        const params ={
            'page-index':param.pageIndex,
            'employee':param.employee,
            'branch':param.branch,
            'today': param.today,
            'month':param.month,
            'year':param.year,
            'from':param.from_date,
            'to':param.to_date,
            'searched':param.searched,
            'page-reload':param.page_reload,
            'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    }
    getTransactionWiseReport(param){
        const url = `api/salary-report/transaction-wise/filter`
        const params ={
                'page-index':param.pageIndex,
                'branch':param.branch,
                'month':param.month,
                'year':param.year,
                'session_year':param.session_years, 
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    } 
    getTransactionWiseExcel(param){
        const url = `api/salary-report/transaction-wise/excel`
        const params ={
                'page-index':param.pageIndex,
                'branch':param.branch,
                'month':param.month,
                'year':param.year,
                'session_year':param.session_years, 
                'searched':param.searched,
                'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    } 
    getMonthWiseReport(param){
        const url = `api/salary-report/month-wise/filter`
        const params ={
                'branch':param.branch,
                'session_year':param.session_years, 
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    } 
    getMonthWiseExcel(param){
        const url = `api/salary-report/month-wise/excel`
        const params ={
                'branch':param.branch,
                'session_year':param.session_years, 
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.getFile(url,headers,params);
    } 
}


export default new SalaryReportService();